<!--
 * @Description: 房源检索
 * @Author: 琢磨先生
 * @Date: 2022-09-05 08:06:09
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-02 11:10:06
-->
<template>
  <el-card class="box">
    <el-form ref="query" class="query" :model="query" :inline="true">
      <el-form-item label="关键字">
        <el-input v-model="query.q" placeholder="请输入名称" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
      </el-form-item>
    </el-form>
    <el-button size="small" @click="onCreateIndex" round icon="Refresh" :loading="saving">创建索引</el-button>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData.data" border>
      <el-table-column label="编号" prop="id" width="80"></el-table-column>
      <el-table-column label="标题" prop="name" width="400"></el-table-column>
      <el-table-column label="地区" prop="area_id" width="140"></el-table-column>
      <el-table-column label="类型" prop="building_id" width="140"></el-table-column>
      <el-table-column label="经度" prop="lng" width="140"></el-table-column>
      <el-table-column label="纬度" prop="lat" width="140"></el-table-column>
      <el-table-column label="房间数" prop="bedroom" width="140"></el-table-column>
      <el-table-column label="床数" prop="bed_count" width="140"></el-table-column>
      <el-table-column label="可住人数" prop="person_sum" ></el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData.counts > 0"
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      saving: false,
      query: {
        pageIndex: 1,
        q: "",
        pageSize:20,
      },
      tableData: {
        counts: 0,
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     *
     */
    loadData() {
      this.loading = true;
      this.$http
        .post("admin/v1/house/search", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    /**
     * 创建检索
     */
    onCreateIndex() {
      this.saving = true;
      this.$http
        .get("admin/v1/house/createIndex")
        .then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg);
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<style  scoped>
</style>